import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  LANGS,
  LANGUAGE_COOKIE_NAME
} from 'helpers/constants';
import { setCookie } from 'helpers/cookies';
import without from 'lodash/without';
import cns from 'classnames';
import styles from './language-switcher.module.scss';

import LanguageIcon from '@material-ui/icons/LanguageOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function LanguageSwitcher({ toggleByIcon, isLanding }) {
  const { t, i18n } = useTranslation('common');
  const current = i18n.language;
  const router = useRouter();
  const { pathname, query } = router;

  const saveLanguage = (lang) => {
    setCookie(LANGUAGE_COOKIE_NAME, lang);
  };

  return (
    <div className={cns(styles.root, { landing: isLanding })}>
      <If condition={toggleByIcon}>
        <div className={styles.icon_toggle}>
          <LanguageIcon />
        </div>
      </If>
      <If condition={!toggleByIcon}>
        <div className={styles.current}>
          <span className={styles.lang}>
            {t(`languages.${current}`)}
          </span>
          <ExpandMoreIcon />
        </div>
      </If>
      <div className={cns(styles.other, { 'by-icon': toggleByIcon })}>
        <For each="lang" of={without(LANGS, current)} index="index">
          <Link
            href={{ pathname, query }}
            locale={lang}
            key={lang}
          >
            <a
              className={styles.item}
              onClick={() => { saveLanguage(lang) }}
            >
              {t(`languages.${lang}`)}
            </a>
          </Link>
        </For>
      </div>
    </div>
  )
};

export default LanguageSwitcher;