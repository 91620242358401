import PropTypes from 'prop-types';
import LanguageSwitcher from 'components/Language';
import styles from './side-menu-header.module.scss';
import Link from 'next/link';
import { useProductEvents } from 'hooks';

const SideMenuHeader = ({ showLanguageSwitcher }) => {
  const events = useProductEvents();
  return (
    <div className={styles.root}>
      <div className={styles.logo}
        onClick={() => { events.send('menu.logo-click.workshopp', { type: 'click' }) }}
      >

        <Link href={'/workbench'} >
          <img src="/logo_orange_unfilled.svg" alt="logo" />
        </Link>
      </div>

      <If condition={showLanguageSwitcher}>
        <div className={styles.language}>
          <LanguageSwitcher />
        </div>
      </If>
    </div>
  )
};

SideMenuHeader.propTypes = {
  showLanguageSwitcher: PropTypes.bool
};

SideMenuHeader.defaultProps = {
  showLanguageSwitcher: true
};

export default SideMenuHeader;