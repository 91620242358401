import { memo, useEffect, useState } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import cns from 'classnames';
import styles from './side-menu-leaf.module.scss';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { Favorite, LineStyleSharp } from '@material-ui/icons';
import { isLocalStorageAvailable, translit } from 'helpers';
import { useProductEvents } from 'hooks';
import { loadMenuFavourites } from 'reducers/app';
import { useDispatch, useSelector } from 'react-redux';

const SideMenuLeaf = ({ text, icon, route, asSimpleLink, onClick, action, isNew, id, favourites, favourite = false, addFavourites, removeFavourites }) => {
	const router = useRouter();
	const events = useProductEvents()
	const dispatch = useDispatch()



	const getContent = () => {

		return (
			<>
				<If condition={icon}>
					{icon}
				</If>
				<div className={styles.text}>
					{text}
				</div>
			</>
		)
	};

	const isActive = () => {
		const routeUrl = new URL("http://example.com" + route);
		const currentRouteUrl = new URL("http://example.com" + router.asPath);

		const currentRoutePathname = currentRouteUrl.pathname;
		const routePathname = routeUrl.pathname;

		// "http://example.com" used to get pathname from URL

		const isComplaintRequired = routeUrl.searchParams.has('complaint');
		const isPathMatching = currentRoutePathname === routePathname;
		const isPathSuggestImprovement = routePathname == "/suggest-improvement";
		const isPathContaiComplain = router.query.complaint === 'true';

		return (isPathMatching && !isPathSuggestImprovement)
			|| (isPathMatching && isPathSuggestImprovement && isPathContaiComplain && isComplaintRequired)
			|| (isPathMatching && isPathSuggestImprovement && !isPathContaiComplain && !isComplaintRequired)
	};

	const _onClick = () => {
		document.body.classList.remove('menu-opened');
		onClick();
	};

	return (
		<div className={styles.root} id={id} >
			<Choose>
				<When condition={route && !asSimpleLink}>
					<Link href={route}>
						<a
							className={cns(styles.link, {
								[styles.active]: isActive()
							})}
							onClick={_onClick}
						>
							{getContent()}
						</a>
					</Link>
				</When>
				<When condition={asSimpleLink}>
					<a
						className={styles.link}
						href={route}
						target="_blank"
						onClick={_onClick}
					>
						{getContent()}
					</a>
				</When>
				<Otherwise>
					<a
						className={styles.link}
						href="#"
						onClick={(e) => {
							e.preventDefault();
							_onClick();
						}}
					>
						{getContent()}
					</a>
				</Otherwise>
			</Choose>

			<If condition={action}>
				<div className={styles.action} onClick={(e) => { e.stopPropagation() }}>
					{action}
				</div>
			</If>
			<If condition={isNew}>
				<div className={styles.new}>
					new
				</div>
			</If>
			<Choose>
				<When condition={favourite || (favourites && favourites.includes(text))}>
					<div className={styles.star} onClick={() => {
						if (!favourite) {

							removeFavourites(text);
						}
					}}>
						<StarIcon />
					</div>
				</When>
				<Otherwise>
					<div className={styles.star} onClick={() => {
						addFavourites(text)
					}}>
						<StarBorderIcon />
					</div>
				</Otherwise>
			</Choose>


		</div>
	);
};

SideMenuLeaf.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.element,
	isActive: PropTypes.bool,
	asSimpleLink: PropTypes.bool,
	route: PropTypes.string,
	onClick: PropTypes.func
};

SideMenuLeaf.defaultProps = {
	asSimpleLink: false,
	isActive: false,
	onClick: () => { }
};

export default memo(SideMenuLeaf);