import PropTypes from 'prop-types';
import UserImage from 'components/User/Image';
import cns       from 'classnames';
import styles    from './user-story.module.scss';

function UserStory ({ image, defaultIcon, hasStory, size }) {
  return (
    <div className={cns(styles.root, styles[size])}>
      <UserImage url={image} size={size} hasStory={hasStory} defaultIcon={defaultIcon}/>
    </div>
  )
};

UserStory.propTypes = {
  image:    PropTypes.string,
  hasStory: PropTypes.bool,
  size:     PropTypes.oneOf(['small', 'medium', 'large']),
  showName: PropTypes.bool
};

UserStory.defaultProps = {
  hasStory: false,
  size: 'large',
  showName: true
};

export default UserStory;