import { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import OutsideClick from 'react-outside-click-handler-lite';
import cns from 'classnames';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import styles from './ui-circle-button.module.scss';

const UICircleButton = ({
  className,
  id,
  size = 'medium',
  icon = <></>,
  text,
  positive = false,
  negative = false,
  inverted = false,
  transparent = false,
  route,
  href,
  target = "_self",
  menu,
  menuDirection = "right",
  onClick = () => { },
  ...rest
}) => {
  const [menuOpened, toggleMenu] = useState(false);
  const _className = cns(styles.button, {
    [styles.positive]: positive,
    [styles.negative]: negative,
    [styles.inverted]: inverted,
    [styles.transparent]: transparent,
    [className]: !isEmpty(className)
  });
  const _rest = omit(rest, 'className');

  return (
    <div className={cns(styles.component, styles[size])} >
      {id && <div id={id} className={styles.recm}></div>}

      <Choose>
        <When condition={route}>
          <Link href={route}>
            <a
              className={_className}
              href={href}
              onClick={onClick}
              {..._rest}
            >
              {icon}
            </a>
          </Link>
        </When>
        <When condition={!isEmpty(href)}>
          <a
            className={_className}
            href={href}
            target={target}
            onClick={onClick}
            {..._rest}
          >
            {icon}
          </a>
        </When>
        <When condition={text}>
          <button
            className={cns(_className, { 'with-text': true })}
            onClick={!isEmpty(menu) ? () => toggleMenu(true) : onClick}
            {..._rest}
          >
            <span className={styles.button_icon}>{icon}</span>
            <span className={styles.button_text}>{text}</span>
          </button>
        </When>
        <Otherwise>
          <button
            className={_className}
            onClick={!isEmpty(menu) ? () => toggleMenu(true) : onClick}
            {..._rest}
          >
            {icon}
          </button>
        </Otherwise>
      </Choose>

      <If condition={menu && !isEmpty(menu) && menuOpened}>
        <OutsideClick onOutsideClick={() => { toggleMenu(!menuOpened) }}>
          <div className={cns(styles.menu, styles[`menu_${menuDirection}`])}>
            <For each="item" of={menu} index="index">
              <div
                key={index}
                className={cns(styles.menu_item, {
                  [styles.negative]: !!item.negative,
                  [styles.disabled]: !!item.disabled
                })}
                onClick={() => {
                  toggleMenu(false);

                  if (!item.disabled) {
                    item.onClick ? item.onClick() : () => { };
                  }
                }}
              >
                <div className={styles.menu_item_icon}>
                  {item.icon || ''}
                </div>

                <div className={styles.menu_item_content}>
                  <div className={styles.menu_item_text}>
                    {item.text || ""}
                  </div>

                  <If condition={item.note && !!item.showNote}>
                    <div className={cns(styles.menu_item_note, {
                      [styles.negative]: item.note.negative
                    })}>
                      {item.note.text}
                    </div>
                  </If>
                </div>
              </div>
            </For>
          </div>
        </OutsideClick>
      </If>
    </div>

  )
};

UICircleButton.propTypes = {
  size: PropTypes.string,
  icon: PropTypes.element,
  text: PropTypes.string,
  positive: PropTypes.bool,
  negative: PropTypes.bool,
  transparent: PropTypes.bool,
  onClick: PropTypes.func
};

export default UICircleButton;