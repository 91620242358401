import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  getUserName,
  getDomainForCookie,
} from 'helpers/helpers';
import { eraseCookie } from 'helpers/cookies';
import {
  TOKEN_COOKIE_NAME,
} from 'helpers/constants';
import isEmpty from 'lodash/isEmpty';
import styles from './user-profile.module.scss';

import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';

const UserProfile = ({ auth, reset }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { profile } = auth;

  const logout = (e) => {
    e.preventDefault();
    reset();
    eraseCookie(TOKEN_COOKIE_NAME, getDomainForCookie());
    eraseCookie(TOKEN_COOKIE_NAME);
    document.body.classList.remove('menu-opened');
    localStorage.removeItem('testClientId')
    router.replace(`/access?tab=auth`);
  };

  const goToProfile = (e) => {
    e.preventDefault();
    router.push(`/profile`);
  };

  return (
    <If condition={!isEmpty(profile)}>
      <div className={styles.root}>
        <div className={styles.data} onClick={goToProfile}>
          <div className={styles.name}>
            {getUserName(profile.name)}
          </div>

          <If condition={profile.garage}>
            <div className={styles.garage}>
              {profile.garage.name}
            </div>
          </If>
        </div>

        <div
          className={styles.logout}
          onClick={

            logout
          }
        >
          <div className={styles.logout_icon}>
            <LogoutIcon />
          </div>
          <div className={styles.logout_text}>
            {t('common.actions.logout')}
          </div>
        </div>
      </div>
    </If>
  );
};

UserProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  reset: PropTypes.func,
};

export default memo(UserProfile);