import PropTypes from 'prop-types';
import cns       from 'classnames';
import styles    from './user-image.module.scss';

import PhotoIcon from '@material-ui/icons/PhotoCameraOutlined';

function UserImage ({ url, size, hasStory, defaultIcon }) {
  return (
    <div
      className={cns(styles.root, styles[size], {
        [styles.has_story]: hasStory
      })}
    >
      <Choose>
        <When condition={url}>
          <div
            className={styles.value}
            style={{backgroundImage: `url('${url}')`}}
          />
        </When>
        <Otherwise>
          <div className={styles.value}>
            <If condition={defaultIcon}>
              {defaultIcon}
            </If>
            <If condition={!defaultIcon}>
              <PhotoIcon/>
            </If>
          </div>
        </Otherwise>
      </Choose>
      
    </div>
  )
};

UserImage.propTypes = {
  url:      PropTypes.string,
  size:     PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  hasStory: PropTypes.bool
};

UserImage.defaultProps = {
  size: 'large',
  hasStory: false
};

export default UserImage;