import { 
  useSelector,
  useDispatch
}                    from 'react-redux';
import UserProfile   from './Component';
import { resetUser } from 'reducers/auth';
import API           from 'api';

function ConnectedUserProfile (props) {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const reset = () => {
    dispatch(API.queries.garage.reset());
    dispatch(API.queries.depots.reset());
    dispatch(resetUser());
  };

  return (
    <UserProfile
      auth={auth}
      reset={reset}
      {...props}
    />
  )
}

export default ConnectedUserProfile;